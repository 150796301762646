export default [
  {
    name: "code",
    type: "string",
    text: "Codice Interno Movimento",
    icon: "mdi-code-tags",
  },
  {
    name: "description",
    type: "string",
    text: "Descrizione",
    icon: "mdi-clipboard-text",
  },
  {
    name: "fileName",
    type: "string",
    text: "Nome file",
    icon: "mdi-file",
  },
  {
    name: "from",
    type: "date",
    text: "Da",
    icon: "mdi-calendar",
  },
  {
    name: "to",
    type: "date",
    text: "A",
    icon: "mdi-calendar",
  },
  {
    name: "completed",
    type: "boolean",
    text: "Caricamento completato",
    icon: "mdi-playlist-check",
  },
];
